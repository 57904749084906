
import Cta from "./components/Cta.vue";
import LoaderCopy from "~/components/Loader/LoaderCopy.vue";

export default {
  components: { Cta, LoaderCopy },

  props: {
    header: {
      type: String,
      required: true,
    },

    body: {
      type: String,
      required: true,
    },

    byline: {
      type: String,
      default: "",
    },

    bylineAbbr: {
      type: String,
      default: "",
    },

    headerByline: {
      type: String,
      default: "",
    },

    loadingEnabled: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    colorForeground: {
      type: String,
      required: true,
    },

    colorBackground: {
      type: String,
      required: true,
    },

    iconId: {
      type: String,
      default: "",
    },

    colorNamespaceIcon: {
      type: String,
      default: "",
    },

    omitClickMore: {
      type: Boolean,
      default: false,
    },

    editEnabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    colorIcon() {
      return this.colorNamespaceIcon.length > 0 ? `--color-${this.colorNamespaceIcon}-500` : this.colorForeground;
    },

    colorIconBackground() {
      return this.colorNamespaceIcon.length > 0 ? `--color-${this.colorNamespaceIcon}-50` : this.colorBackground;
    },
  },

  methods: {
    containerEndControlsPopulated() {
      return this.$slots.containerEndControls && this.$slots.containerEndControls.length > 0;
    },
  },
};
