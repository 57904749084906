import { BarData, TreemapData, BoxPlotData, HeatMapData, OptionsConfig, PieData, ScatterData } from "./options";

export const barExampleData: BarData = {
  xaxis: {
    categories: ["Feburar", "March", "April", "May", "June", "July", "August", "Sepember", "October"],
  },

  series: [
    {
      name: "Net Profit",
      data: [44, 55, 57, 56, 61, 58, 63, 60, 66],
    },

    {
      name: "Revenue",
      data: [76, 85, 101, 98, 87, 105, 91, 114, 94],
    },

    {
      name: "Free Cash Flow",
      data: [35, 41, 36, 26, 45, 48, 52, 53, 41],
    },
  ],
};

export const boxPlotExampleData: BoxPlotData = {
  series: [
    {
      type: "boxPlot",
      data: [
        {
          x: "Jan 2015",
          y: [54, 66, 69, 75, 88],
        },
        {
          x: "Jan 2016",
          y: [43, 65, 69, 76, 81],
        },
        {
          x: "Jan 2017",
          y: [31, 39, 45, 51, 59],
        },
        {
          x: "Jan 2018",
          y: [39, 46, 55, 65, 71],
        },
        {
          x: "Jan 2019",
          y: [29, 31, 35, 39, 44],
        },
        {
          x: "Jan 2020",
          y: [41, 49, 58, 61, 67],
        },
        {
          x: "Jan 2021",
          y: [54, 59, 66, 71, 88],
        },
      ],
    },
  ],
};

export const heatMapExampleData: HeatMapData = {
  series: [
    {
      name: "A",
      data: [0, 9, 51, 8, 58],
    },
    {
      name: "B",
      data: [47, 73, 70, 90, 34],
    },
    {
      name: "C",
      data: [66, 48, 56, 39, 22],
    },
    {
      name: "D",
      data: [23, 76, 31, 63, 46],
    },
    {
      name: "E",
      data: [34, 67, 34, 16, 47],
    },
  ],
};

export const pieExampleData: PieData = {
  labels: ["Rent and utilities", "Home office", "Advertising expenses", "Insurance", "Legal and professional fees", "Retirement plans"],

  series: [10430, 2000, 3400, 1300, 4000, 3450],
};

export const scatterExampleData: ScatterData = {
  series: [
    {
      name: "Sample A",

      data: [
        [16.4, 5.4],
        [10.9, 1],
        [13.6, 3.2],
        [10.9, 7.4],
        [10.9, 0],
        [10.9, 8.2],
        [16.4, 0],
        [16.4, 1.8],
        [13.6, 0.3],
        [13.6, 0],
      ],
    },
    {
      name: "Sample B",

      data: [
        [1.7, 11],
        [5.4, 8],
        [9, 17],
        [1.9, 4],
        [3.6, 12.2],
        [1.9, 14.4],
        [1.9, 9],
        [1.9, 13.2],
        [1.4, 7],
        [6.4, 8.8],
        [3.6, 4.3],
        [1.6, 10],
        [9.9, 2],
        [7.1, 15],
        [1.4, 0],
        [3.6, 13.7],
        [1.9, 15.2],
        [6.4, 16.5],
        [0.9, 10],
        [4.5, 17.1],
        [10.9, 10],
        [0.1, 14.7],
        [9, 10],
        [12.7, 11.8],
        [2.1, 10],
        [2.5, 10],
        [2.9, 11.5],
        [7.1, 10.8],
        [2.1, 12],
      ],
    },
    {
      name: "Sample C",

      data: [
        [10.7, 3],
        [11.7, 20],
        [12.6, 5],
        [15.4, 0],
        [16.4, 0],
      ],
    },
  ],
};

export const treemapExampleData: TreemapData = {
  series: [
    {
      data: [
        {
          x: "Rent and utilities",
          y: 10430,
        },
        {
          x: "Home office",
          y: 2000,
        },
        {
          x: "Advertising expenses",
          y: 3400,
        },
        {
          x: "Insurance",
          y: 1300,
        },
        {
          x: "Legal and professional fees",
          y: 4000,
        },
        {
          x: "Retirement plans",
          y: 3450,
        },
        {
          x: "Pens",
          y: 400,
        },
        {
          x: "Stamps",
          y: 700,
        },
        {
          x: "Envelopes",
          y: 2700,
        },
        {
          x: "Misc",
          y: 1530,
        },
      ],
    },
  ],
};
